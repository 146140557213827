<template>
  <div class="ModalWrapper" v-show="showModal">
    <div class="SendRequestModal">
      <div class="Title">
        <span>{{ setHeader }}</span>
        <div
          class="Close"
          @click.native="
            showModal = false;
            this.$emit('sendFalseToParent');
            step = 2;
          "
        ></div>
      </div>
      <div class="ChooseType" v-show="step == 1">
        <ButtonGradient
          class="AdaptiveButton"
          v-for="type in company_types"
          :ButtonText="type"
          :key="type"
          @click.native="
            step = 2;
            request.type = type;
          "
        />
      </div>
      <div class="ModalFields" v-show="step == 2">
        <div class="ModalFormInputs">
          <div class="ModalFormInput">
            <label>Имя</label>
            <input type="text" v-model="request.f_name" />
            <label>Отчество</label>
            <input type="text" v-model="request.m_name" />
            <label>Фамилия</label>
            <input type="text" v-model="request.l_name" />

            <label>Регион</label>

            <el-select
              v-model="request.region"
              class="RDSelectField"
              placeholder="Выберите"
              size="large"
            >
              <el-option
                v-for="item in region_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>

            <input
              v-if="isOtherRegion"
              v-model="request.region_other"
              type="text"
              placeholder="Укажите название региона"
            />

            <template v-if="request.type === 'ВУЗ'">
              <label>ВУЗ</label>
              <el-select
                v-model="request.univer"
                class="RDSelectField"
                placeholder="Выберите"
                size="large"
              >
                <el-option
                  v-for="item in univer_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <input
                v-if="isOtherUniver"
                v-model="request.univer_other"
                type="text"
                placeholder="Укажите название ВУЗа"
              />
            </template>

            <label>Наименование организации/учреждения</label>
            <input type="text" v-model="request.company_name" />

            <template v-if="request.type === 'ВУЗ'">
              <label>Должность</label>
              <input
                type="text"
                v-model="request.position"
                :class="
                  request.position == null || request.position === ''
                    ? 'input-invalid'
                    : 'input-valid'
                "
              />
            </template>

            <label>Электронная почта</label>
            <input
              type="email"
              :class="isValidEmail ? 'input-valid' : 'input-invalid'"
              v-model="request.email"
            />
            <label>Номер телефона</label>
            <input
              type="tel"
              v-model="request.phone"
              :class="isValidPhoneNumber ? 'input-valid' : 'input-invalid'"
            />
            <span lang="ru" class="notificationText">{{ msg }}</span>
            <ButtonGradient
              class="sendRequestBtn"
              @click="sendRequest"
              value="Отправить заявку"
              ButtonText="Отправить заявку"
            />
          </div>
        </div>
      </div>
      <div v-show="step == 3" class="notificationText">
        В ближайшее время с вами свяжется наш менеджер.
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGradient from "@/components/CustomElements/ButtonGradient";
import { createRequest } from "@/api/company_requests";

import { getRegionAndUniverList } from "@/api/user.js";

export default {
  name: "SendRequest",
  components: { ButtonGradient },
  props: {
    showModal: Boolean,
    setType: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      step: 2,
      company_types: ["Партнер", "ВУЗ", "Организация", "Гос.учреждение"],
      request: {
        f_name: "",
        m_name: "",
        l_name: "",
        email: "",
        phone: "",
        type: "",
        company_name: "",
        region: "",
        region_other: "",
        univer: null,
        univer_other: "",
        position: "",
      },

      region_list: [],
      univer_list: [],

      msg: "",
    };
  },
  async created() {
    await getRegionAndUniverList().then((response) => {
      this.region_list = response.data;
    });

    this.request.type = this.setType;
  },
  computed: {
    isOtherRegion() {
      let other_reg = this.region_list.find(
        (region) => region.id === this.request.region
      );
      this.univer_list = other_reg?.univers == null ? [] : other_reg?.univers;

      return other_reg !== undefined && other_reg.name === "Другое";
    },
    isOtherUniver() {
      let other_univ = this.univer_list.find(
        (univer) => univer.id === this.request.univer
      );
      return other_univ !== undefined && other_univ.name === "Другое";
    },
    setHeader() {
      switch (this.step) {
        case 1: {
          return "Выберите тип компании";
        }
        case 2: {
          return "Заполните заявку";
        }
        case 3: {
          return "Ваша заявка отправлена";
        }
      }
    },
    isValidPhoneNumber() {
      const validationRegex = /(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/g;
      return this.request.phone.match(validationRegex) ? true : false;
    },
    isValidEmail() {
      const validationRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
      return this.request.email.match(validationRegex) ? true : false;
    },
  },
  methods: {
    sendRequest() {
      if (!this.isValidPhoneNumber || !this.isValidEmail) {
        this.msg =
          "Проверьте правильность введенных контактных данных и попробуйте отправить заявку снова.";
        return;
      }

      if (this.request.type === "ВУЗ") {
        if (this.request.position == null || this.request.position === "") {
          this.msg = 'Вам нужно заполнить поле "Должность"';
          return;
        }
      }
      createRequest(this.request).then((res) => {
        if (!res.message) this.step = 3;
        else {
          this.msg = res.message;
        }
      });

      for (var fieldname in this.request) {
        this.request[fieldname] = "";
      }
    },
  },
};
</script>
<style>
.el-select input.el-input__inner {
  height: 3rem !important;
}
</style>
<style scoped>
.el-select {
  /* height: 48px !important; */
}

.notificationText {
  color: #fff;

  /* text */
  hyphens: auto;
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.45313rem */
}

.ModalFormInput input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  height: 3rem;
  width: 100%;

  color: #fff;

  /* text medium */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 1.45313rem */

  padding: 0.8125rem 0.75rem;

  outline: none;

  border-radius: 0.25rem;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );
  box-shadow: -33px 45px 90px 0px #17122b;
}

.Title > * {
  margin-top: auto;
  margin-bottom: auto;
}
.Title > .Close {
  position: relative;
  display: block;

  width: 20px;
  height: 20px;

  margin-left: 20px;

  background: url("./../../assets/img/ModalCloseButton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.Title > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: 500;
}
.sendRequestBtn {
  margin-top: 1.88rem;
  width: 100%;
}
.SendRequestModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;

  width: fit-content;
  height: fit-content;

  max-width: 80%;
  max-height: 90%;

  overflow-y: auto;

  padding: 48px;
  margin: auto;

  background-image: url("./../../assets/img/profile/Stages/UpperBG.svg"),
    url("./../../assets/img/profile/Stages/BottomBG.svg");

  background-position: 0px 0px, 0px 100%;
  background-size: 100% auto;
  background-attachment: inherit;
  background-repeat: no-repeat;

  background-color: #201e48;
  border-radius: 20px;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.SendRequestModal::-webkit-scrollbar {
  width: 0px;
}
.SendRequestModal::-webkit-scrollbar-track {
  background: transparent;
}
.SendRequestModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.Title {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ChooseType {
  position: relative;

  width: 409px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.ChooseType > * {
  width: calc(50% - 20px);
}

.ModalFields {
  position: relative;

  width: 409px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ModalFormInputs {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;

  flex-direction: column;
  gap: 10px;
}

.ModalFormInputs > .ModalFormInput {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;

  flex-direction: column;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-feature-settings: "calt" off;

  margin: 0% auto 20px auto;

  color: #e8e7ec;
}
/* .ModalFormInputs > .ModalFormInput > *:nth-child(2):focus-visible {
  outline: 2px solid rgb(130, 111, 238);
} */

.input-invalid {
  border-top: 1px solid #413e74 !important;
  border-left: 1px solid #413e74 !important;
  border-right: 1px solid #413e74 !important;
  border-bottom: 6px solid rgba(238, 74, 74, 0.651) !important;
  border-radius: 4px !important;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  ) !important;
  padding-left: 10px;
  height: 50px;
  margin-bottom: 10px;
  color: #e8e7ec;
  outline: none;
}
.input-valid {
  border-top: 1px solid #413e74 !important;
  border-left: 1px solid #413e74 !important;
  border-right: 1px solid #413e74 !important;
  border-bottom: 6px solid rgba(55, 204, 55, 0.629) !important;
  border-radius: 4px !important;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  ) !important;
  padding-left: 10px;
  height: 50px;
  margin-bottom: 10px;
  color: #e8e7ec;
  outline: none;
}

label {
  color: #e8e7ec;
  margin-bottom: 3px;
  margin-top: 0.56rem;
}

.login-btn {
  text-transform: capitalize;
  color: black;
  padding: 0.75rem 3rem;
  height: auto !important;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 14px !important;
  background-color: #ffcf66;
  font-weight: 600;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .ModalWrapper .SendRequestModal {
    padding: 24px;
  }
  .ModalWrapper .ChooseType {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }
  .AdaptiveButton {
    height: 3rem;
  }
  .ModalWrapper .ChooseType > .AdaptiveButton,
  .ModalWrapper .ModalFields {
    width: 100%;

    /* padding: 0px 6px 0px 0px; */

    overflow-y: auto;
  }

  .ModalWrapper .ModalFormInput input {
    box-shadow: none;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .ModalWrapper .ChooseType {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }

  .ModalWrapper .ChooseType > .AdaptiveButton,
  .ModalWrapper .ModalFields {
    width: 100%;

    padding: 0px 6px 0px 0px;

    overflow-y: auto;
  }

  .ModalWrapper .ModalFormInput input {
    box-shadow: none;
  }
}
</style>
