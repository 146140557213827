<template>
  <div class="ButtonGradientNew">
    <span class="centerText">
      <span class="Text">{{ ButtonText }}</span>
    </span>
  </div>
</template>

<style scoped>
.ButtonGradientNew {
  cursor: pointer;
  position: relative;
  width: fit-content;
  padding: 12px 24px;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);

  text-align: center;
  color: #ffffff;
  border: 0px solid;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  border-radius: 4px;
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);

  transition: 0.4s;
}
.ButtonGradientNew:hover {
  opacity: 0.8;
}

.centerText {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;

  margin: auto;
}
.centerText > * {
  margin-top: auto;
  margin-bottom: auto;
}

.Text {
  position: relative;
  width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  color: #ffffff;
  text-align: center;
}
</style>

<script>
export default {
  name: "ButtonGradientBorder",
  props: {
    ButtonText: String,
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
