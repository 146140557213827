<template>
  <div>
    <div class="project-card">
      <template v-if="NeededSelectButton">
        <el-button
          class="read-more"
          @click="SelectProject(project)"
          :class="{
            project_choosed: findProjectInProjectList(project),
          }"
        >
          <i
            class="fas fa-check-circle"
            v-if="findProjectInProjectList(project)"
          ></i>
          <i
            class="fas fa-plus-circle"
            v-if="!findProjectInProjectList(project)"
          ></i>
          <p class="choose" v-if="!findProjectInProjectList(project)">
            Выбрать <br />
            проект
          </p>
          <p class="choose" v-if="findProjectInProjectList(project)">
            Проект <br />
            выбран
          </p>
        </el-button>
      </template>
      <div class="project-info">
        <template v-if="project.hasOwnProperty('Organization')">
          <p class="project-name">
            <span v-if="project.project_type" class="type-project"
              >{{ project.project_type }} :</span
            >
            {{ project.project_name }} (Заказчик:
            {{ project["Organization"]["organization_name"] }})
          </p>

          <div class="project-info-desc">
            <p
              class="project-desc ql-editor"
              v-html="project.project_short_desc"
            ></p>

            <span class="social-project" v-if="project?.isSocial == 1">
              <span class="social-project-icon"></span>
              <span class="social-project-text">Социальный проект</span>
            </span>

            <div
              class="project-badges"
              v-if="
                project.Directions != null && project.Directions.length != 0
              "
            >
              Направления:
              <span
                class="badge badge-default"
                :style="'background-color: ' + tag.color + '!important'"
                v-for="tag in project.Directions"
                :key="tag.id"
              >
                {{ tag.name }}
              </span>
            </div>
          </div>
        </template>

        <template
          v-else-if="
            project.hasOwnProperty('project_type') ||
              project.hasOwnProperty('offer_materials')
          "
        >
          <p class="project-name">
            <span
              v-if="
                project.project_type ||
                  project.hasOwnProperty('offer_materials')
              "
              class="type-project"
              >{{
                project.hasOwnProperty("offer_materials") == true
                  ? "Свой проект"
                  : project.project_type
              }}:
            </span>
            {{ project.name }}
          </p>
          <div class="project-info-desc">
            <p class="project-desc ql-editor" v-html="project.description"></p>

            <span class="social-project" v-if="project?.isSocial == 1">
              <span class="social-project-icon"></span>
              <span class="social-project-text">Социальный проект</span>
            </span>

            <div
              class="project-badges"
              v-if="
                project.Directions != null && project.Directions.length != 0
              "
            >
              Направления:
              <span
                class="badge badge-default"
                :style="'background-color: ' + tag.color + '!important'"
                v-for="tag in project.Directions"
                :key="tag.id"
              >
                {{ tag.name }}
              </span>
            </div>
          </div>
        </template>

        <template v-else>
          <p class="project-name">
            {{ project.name }}
          </p>
          <div class="project-info-desc">
            <p class="project-desc ql-editor" v-html="project.description"></p>

            <span class="social-project" v-if="project?.isSocial == 1">
              <span class="social-project-icon"></span>
              <span class="social-project-text">Социальный проект</span>
            </span>

            <div
              class="project-badges"
              v-if="
                project.Directions != null &&
                  typeof project.Directions != String &&
                  project.Directions.length != 0
              "
            >
              Направления:
              <span
                class="badge badge-default"
                :style="'background-color: ' + tag.color + '!important'"
                v-for="tag in project.Directions"
                :key="tag.id"
              >
                {{ tag.name }}
              </span>
            </div>
          </div>
        </template>
      </div>
      <div class="project-img">
        <img v-if="project?.url_img != null" :src="project.url_img" />
        <img v-else src="@/assets/img/website.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
import { findByIdProject } from "@/api/project.js";
import { mapGetters } from "vuex";
export default {
  name: "ProjectCard",
  props: {
    project: Object,
    NeededSelectButton: Boolean,
    SelectProject: { type: Function },
    SelectedProject: Object,
    checkClosedProjects: Boolean,
  },
  data() {
    return {
      projectTags: [],
    };
  },
  methods: {
    getDirections(id) {
      findByIdProject(id).then((response) => {
        return response.data.Directions;
      });
    },

    findProjectInProjectList(project) {
      if (this.SelectedProject == null) {
        return false;
      }

      let SelectedProjectIsOrg = this.SelectedProject.hasOwnProperty(
        "Organization"
      );
      let ProjectIsOrg = project.hasOwnProperty("Organization");

      if (SelectedProjectIsOrg != ProjectIsOrg) {
        return false;
      }

      if (SelectedProjectIsOrg) {
        if (this.SelectedProject["project_name"] != project["project_name"]) {
          return false;
        }

        if (this.SelectedProject["id"] != project["id"]) {
          return false;
        }

        if (
          this.SelectedProject["project_full_desc"] !=
          project["project_full_desc"]
        ) {
          return false;
        }

        return true;
      } else {
        if (this.SelectedProject["name"] != project["name"]) {
          return false;
        }

        if (this.SelectedProject["id"] != project["id"]) {
          return false;
        }

        if (this.SelectedProject["description"] != project["description"]) {
          return false;
        }

        return true;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.core.prod.css";
@import "./../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.prod.css";

.social-project {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;

  width: fit-content;
  height: fit-content;

  margin-top: auto;
  margin-bottom: 26px;
}
.social-project > * {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}

.social-project-icon {
  display: block;
  width: 34px;
  height: 34px;
  background: url("./../../assets/img/profile/ProfileProjectCard/Social.svg");

  margin-right: 10px;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.social-project-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;

  /* or 125% */

  color: #ffffff;
}

.type-project {
  color: #d64a4a;
  font-weight: 600;
}
a {
  color: white !important;
}
.btns-block {
  display: flex;
  justify-content: flex-end;
  width: 97%;
}

.btns-block button {
  border-radius: 20px;
  border: none;
  background-color: #d64a4a;
  padding: 5px 15px;
  color: white;
  opacity: 0.9;
}
.btns-block button:hover {
  opacity: 1;
}

.badge-proj {
  color: #d64a4a;
  margin-right: 5px;
}

.el-tag {
  background-color: #dfdfdf !important;
  color: black !important;
  font-weight: 500;
}
.el-tag > i {
  color: black !important;
  /* background-color: #212529 !important; */
}
.badge {
  margin-bottom: 5px !important;
  font-size: 0.7rem;
  font-weight: 600;
  background-color: #d64a4a !important;
  margin-left: 5px;
}

.project-badges {
  font-size: 0.8rem;
  font-weight: 600;
  color: #3f3f3f;
  padding-bottom: 20px !important;
}

.project-info-desc {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}
.project-name {
  text-align: left;
  font-weight: 600;
  font-size: 1rem;
  color: #212529;
}

.project-desc {
  width: 100%;
  text-align: start;
  font-size: 0.9rem;
  color: #222324;
  padding: 0 !important;
}

.el-badge {
  background-color: transparent !important;
}

.project-info {
  overflow-y: auto;
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project-img {
  width: 20%;
  display: flex;
}

.project-img > img {
  object-fit: cover;
  width: 100%;
  border-radius: 0px 15px 15px 0px !important;
  opacity: 0.8;
}

.project-card {
  display: flex;
  margin: 0px 20px 25px 0px;
  height: 350px;
  background: white;
  box-shadow: none !important;
  border: 0px solid #d4d4d4 !important;
  border-radius: 20px;
}

.project-change {
  display: flex;
  justify-content: right;
  padding-right: 2vw;
}
</style>

<style scoped>
.step3 {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.chart-container {
  padding-top: 10px !important;
}
.el-select {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px !important;
}

.project_choosed {
  background-color: rgb(18, 160, 77) !important;
  /* opacity: 0.7; */
  color: white !important;
}

.project-card:hover {
  /* opacity: 0.7; */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2) !important;
}

.read-more {
  width: 100px;
  font-family: "Montserrat";
  border-radius: 15px 0px 0px 15px;
  border: none;
  border-right: 0px solid #e4e4e4 !important;
}

.choose {
  margin-top: 10px;
  color: #fff;
}
.fa-plus-circle {
  color: white;
}
.badge {
  margin-bottom: 5px !important;
  font-size: 0.7rem;
  font-weight: 600;
  background-color: #d64a4a !important;
  margin-left: 5px;
}

.project-badges {
  font-size: 0.75rem;
  font-weight: 600;
  color: #3f3f3f;
}

.project-info-desc {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}
.project-info-desc > div {
  text-align: left;
}
.project-info-desc > div > *:nth-child(1) {
  margin-bottom: 1vh;
}

.project-name {
  text-align: left;
  font-weight: 600;
  font-size: 1rem;
  color: #212529;
}

.project-desc {
  text-align: start;
  font-size: 0.9rem;
  color: #222324;
}

.el-badge {
  background-color: transparent !important;
}

.sup {
  background-color: #212529;
}

.project-info {
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  overflow: hidden;
}

.project-img {
  width: 20%;
  display: flex;
}

.project-img > img {
  object-fit: cover;
  width: 100%;
  border-radius: 0px 15px 15px 0px !important;
  opacity: 1;
}

.project-card {
  width: 100%;
  display: flex;
  margin: 0px 20px 25px 0px;
  height: 350px;
  box-shadow: none !important;
  border: 1px solid #d4d4d4 !important;
}

.el-scrollbar {
  width: 100%;
  border-radius: 0px !important;
}

.step2 {
  background-color: transparent !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.next-btn {
  border-radius: 15px;
  padding: 9px;
  width: 13rem;
  font-size: 0.9rem;
  background-color: #d64a4a !important;
  color: #fff !important;
  border: none !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* .btn-offer-project {
  border-radius: 15px;
  padding: 9px;
  width: 15rem;
  font-size: 0.9rem;
  background-color: #21b85d !important;
  color: #fff !important;
  border: none !important;
  margin-top: 20px;
  margin-bottom: 20px;
} */

.next-btn:hover {
  background-color: #d64a4a !important;
  opacity: 0.9 !important;
  color: #fff !important;
  border: none !important;
}
.step-desc {
  text-align: left;
  font-weight: 600;
  color: black;
}
.stepper {
  margin: 20px 0px;
  display: flex;
  min-height: 50vh;
  width: 100%;
  justify-content: center;
}
.steps {
  width: 10%;
  margin-right: 80px;
}

.user-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.user-field {
  color: black;
  border: 1px solid #bdbdbd;
  border-radius: 14px !important;
  padding: 10px;
}

.user-field:focus-visible {
  outline: none !important;
}

.step1 {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
}

.container {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.header-welcome {
  text-align: start;
  font-size: 2rem;
  color: black;
  font-weight: 600;
}

.welcome {
  font-family: "Montserrat";
  min-height: 100vh;
  background-image: url("../../assets/img/_landing/karakul2.svg") !important;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  z-index: 0;
  margin-top: 20px;
}
.info {
  text-align: left;
}
.el-button {
  border-radius: 15px 0px 0px 15px !important;
  background-color: #c72b2b;
}
.el-button:hover {
  background-color: rgb(216, 77, 77);
}
</style>

<style scoped>
@media (max-width: 479px) {
  .project-card > .el-button {
    width: 20%;
    padding: 0%;
    text-align: center;
  }
  .project-card > .el-button .choose {
    font-size: 0.7rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .project-card > .el-button {
    width: 20%;
    padding: 0%;
    text-align: center;
  }
  .project-card > .el-button .choose {
    font-size: 0.7rem;
  }
}
</style>
