<template>
  <div class="auth-restricted">
    <template v-if="auth_failed_show == true">
      <h1>{{ auth_failed_reason }}</h1>
      <el-button @click.native="redirect" type="primary" plain>{{
        auth_failed_button_text
      }}</el-button>
    </template>
  </div>
</template>

<script>
import { logout, refresh } from "@/api/auth.js";
import { mapMutations } from "vuex";

import gsap from "gsap";

export default {
  components: {},
  data() {
    return {
      auth_user: null,
      auth_failed: true,
      auth_failed_show: false,
      auth_failed_reason: "",
      auth_failed_button_text: "",
      auth_failed_redirect_link: "",
      auth_failed_need_logout: false,
    };
  },
  async created() {
    this.updateUser();
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    showAuthLayer() {
      gsap.fromTo(
        ".auth-restricted",
        {
          display: "none",
          opacity: 0,
        },
        {
          display: "flex",
          opacity: 1,

          duration: 1,
          delay: 0.0,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );
    },

    /* Check user & auth fail */
    async updateUser() {
      try {
        const response = await refresh();
        this.profile_original = response.user;
        this.auth_failed = false;
      } catch (e) {
        this.auth_failed_reason = "Вы не авторизованы";
        this.auth_failed_button_text = "Главная";
        this.auth_failed_redirect_link = "/";
        this.auth_failed_need_logout = true;
        this.auth_failed_show = true;
        this.showAuthLayer();
      }
    },
    redirect() {
      if (this.auth_failed_need_logout) {
        try {
          const response = logout();
          this.$store.dispatch("auth/logout");
          this.$router.push(this.auth_failed_redirect_link);
        } catch (error) {
          this.$router.push(this.auth_failed_redirect_link);
        }
      } else {
        this.$router.push(this.auth_failed_redirect_link);
      }
    },
    /* END Check user & auth fail */
  },
};
</script>

<style scoped>
.auth-restricted {
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  display: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  z-index: 99999;
  background-color: rgb(255, 255, 255);

  overflow: hidden;
}
.auth-restricted > h1 {
  color: #000;
  font-size: 2vw;
  margin-bottom: 1vw;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
