<template>
  <div class="OctocoinsWrapper" :style="`border-color: ${BorderColor};`">
    <svg
      width="8"
      height="17"
      viewBox="0 0 8 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="coinsLogo"
    >
      <mask
        id="mask0_6120_22335"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="8"
        height="17"
      >
        <path
          d="M1.20025 6.07749L0 8.89404L1.20025 11.7106L2.34315 14.5509L5.18345 15.6938L7.22199 16.5625V14.3624L5.92015 13.8076L3.89951 12.9945L3.08643 10.9739L2.23256 8.97015L3.08643 6.96641L3.89951 4.94577L5.92015 4.1327L7.22199 3.57793L7.22199 0.432865L5.93858 1.7725L5.18345 2.09429L2.34315 3.23719L1.20025 6.07749Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_6120_22335)">
        <path
          d="M-0.863281 18.7891L-0.863281 -0.238424H7.9993L7.9993 18.7891H-0.863281Z"
          fill="#1E768E"
        />
        <path
          d="M-0.863281 18.4336L-0.863281 -0.593893H7.9993L7.9993 18.4336H-0.863281Z"
          :fill="IconHexColor"
        />
      </g>
      <mask
        id="mask1_6120_22335"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="4"
        height="9"
      >
        <path
          d="M4.57784 7.51466L4 8.87064L4.57784 10.2266L5.12807 11.594L6.49549 12.1443L7.47691 12.5625V11.5033L6.85016 11.2362L5.87736 10.8448L5.48591 9.87195L5.07483 8.90729L5.48591 7.94262L5.87736 6.96981L6.85016 6.57837L7.47691 6.31129L7.47691 4.79715L6.85903 5.44209L6.49549 5.59701L5.12807 6.14724L4.57784 7.51466Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask1_6120_22335)">
        <path
          d="M3.58594 13.6328L3.58594 4.47234H7.85268L7.85268 13.6328H3.58594Z"
          fill="#9353D2"
        />
        <path
          d="M3.58594 13.6328L3.58594 4.47234H7.85268L7.85268 13.6328H3.58594Z"
          :fill="IconHexColor"
        />
      </g>
    </svg>

    <div class="coinsLogo"></div>

    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "OctoCoins",
  props: {
    IconHexColor: {
      type: String,
      default: "#ffffff",
    },
    BorderColor: {
      type: String,
      default: "#ffffff",
    },
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.OctocoinsWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;

  padding: 5px 10px;

  width: fit-content;
  height: fit-content;

  border: 1px solid white;
  border-radius: 20px;
}

.OctocoinsWrapper > * {
  margin: auto 0%;
}

/*
TODO: с SVG какая-то шляпа на ширине <= 700px
*/
div.coinsLogo {
  position: relative;
  display: none;

  width: 8px;
  height: 17px;

  background: url("./../../assets/img/MobileLogoCoins.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 768px) {
  svg.coinsLogo {
    display: none;
  }
  div.coinsLogo {
    display: block;
  }
}
</style>
