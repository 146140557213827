<template>
  <div class="ModalWrapper" v-show="showModal">
    <div class="SendRequestModal">
      <div
        class="Close"
        @click.native="
          showModal = false;
          this.$emit('sendCloseRegistrationModal');
        "
      ></div>
      <div class="Title">
        <span>{{ setHeader }}</span>
      </div>

      <div class="ChooseFieldForRegList noselect">
        <div class="Col">
          <div
            :class="{
              Card: true,
              ActiveMobile: choosedCard == card - 1,
            }"
            v-for="card in 3"
            :key="card"
            :style="getCardBG(cards[card - 1].bg)"
            @click="
              choosedCard == card - 1
                ? (choosedCard = null)
                : (choosedCard = card - 1)
            "
          >
            <span class="Head">{{ cards[card - 1].name }}</span>
            <span class="Desc">{{ cards[card - 1].desc }}</span>
            <span
              class="Button noselect"
              @click="
                cards[card - 1].useRegLink
                  ? redirectToReg()
                  : openOrgRegistration(cards[card - 1].type)
              "
            >
              {{
                cards[card - 1].useRegLink
                  ? "Зарегистрироваться"
                  : "Оставить заявку"
              }}
            </span>
          </div>
        </div>
        <div class="Col">
          <div
            :class="{
              Card: true,
              ActiveMobile: choosedCard == card + 2,
            }"
            v-for="card in 2"
            :key="card"
            :style="getCardBG(cards[cards.length - 2 - 1 + card].bg)"
            @click="
              choosedCard == card + 2
                ? (choosedCard = null)
                : (choosedCard = card + 2)
            "
          >
            <span class="Head">{{
              cards[cards.length - 2 - 1 + card].name
            }}</span>
            <span class="Desc">{{
              cards[cards.length - 2 - 1 + card].desc
            }}</span>
            <span
              class="Button noselect"
              @click="
                cards[cards.length - 2 - 1 + card].useRegLink
                  ? redirectToReg()
                  : openOrgRegistration(cards[cards.length - 2 - 1 + card].type)
              "
            >
              {{
                cards[cards.length - 2 - 1 + card].useRegLink
                  ? "Зарегистрироваться"
                  : "Оставить заявку"
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGradient from "@/components/CustomElements/ButtonGradient";

export default {
  name: "RegistrationModal",
  components: { ButtonGradient },
  props: {
    showModal: Boolean,
    openOrgRegistration: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cards: [
        {
          name: `Я студент(-ка) ВУЗа или колледжа`,
          desc: "Хочу пройти практику и получить опыт работы в IT",
          bg: require("../../assets/img/Landing/CardsBG/OneStudent.svg"),
          useRegLink: true,
          type: null,
        },
        {
          name: `Я представитель учебного заведения`,
          desc: "Хочу отслеживать успеваемость студентов во время практики",
          bg: require("../../assets/img/Landing/CardsBG/TwoVUZ.svg"),
          useRegLink: false,
          type: "ВУЗ",
        },
        {
          name: `Я представитель бизнеса`,
          desc: "Хочу развивать бизнес и получить решение для своих IT-задач",
          bg: require("../../assets/img/Landing/CardsBG/ThreeBusiness.svg"),
          useRegLink: false,
          type: "Организация",
        },
        {
          name: `Я партнер`,
          desc: "Хочу найти талантливого специалиста в свой штат",
          bg: require("../../assets/img/Landing/CardsBG/ThreeBusiness.svg"),
          useRegLink: false,
          type: "Партнер",
        },
        {
          name: `Я представитель гос. учреждения`,
          desc: "Хочу реализовывать социальные проекты",
          bg: require("../../assets/img/Landing/CardsBG/ThreeBusiness.svg"),
          useRegLink: false,
          type: "Гос.учреждение",
        },
      ],

      choosedCard: null,
    };
  },
  async created() {},
  computed: {
    setHeader() {
      return "Выберите к какой категории пользователя вы относитесь";
    },
  },
  methods: {
    getCardBG(img) {
      return `
      background: url("${img}");
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: auto;
      background-color: #1e1b4a;
      `;
    },

    redirectToReg() {
      this.$router.push("/auth/signup-login#pills-register");
    },
  },
};
</script>

<style>
.el-select input.el-input__inner {
  height: 3rem !important;
}
</style>
<style scoped>
.Card {
  position: relative;
  display: flex;

  padding: 20px;
  width: 264px;
  height: 212px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  border-radius: 12px;

  background-color: #1e1b4a;

  transition-timing-function: ease-in;
  transition: 0.2s;
}
.Card:hover {
  background-color: #27235c !important;
}

.Card > .Head {
  position: relative;
  display: block;

  width: fit-content;
  height: 48px;

  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: white;
}
.Card > .Desc {
  position: relative;
  display: block;

  width: fit-content;
  height: 60px;

  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #d3caffb2;
}
.Card > .Button {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: white;

  text-align: center;

  border-radius: 4px;
  background-color: #656bffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;
}

.ChooseFieldForRegList {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 20px;
  width: fit-content;
  height: fit-content;
}
.ChooseFieldForRegList > div {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  gap: 20px;
  width: fit-content;
  height: fit-content;

  margin: 0% auto;
}

.el-select {
  /* height: 48px !important; */
}

.notificationText {
  color: #fff;

  /* text */
  hyphens: auto;
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.45313rem */
}

.ModalFormInput input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  height: 3rem;
  width: 100%;

  color: #fff;

  /* text medium */
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 1.45313rem */

  padding: 0.8125rem 0.75rem;

  outline: none;

  border-radius: 0.25rem;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );
  box-shadow: -33px 45px 90px 0px #17122b;
}

.Title > * {
  margin-top: auto;
  margin-bottom: auto;
}
.Close {
  position: absolute;
  display: block;

  top: 0;
  right: 0;

  width: 32px;
  height: 32px;

  background: url("./../../assets/img/Landing/CloseButtonForRegModal.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  border-radius: 4px;

  background-color: #656bff40;

  z-index: 10;
}
.Title > span {
  color: #fff;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-right: 195px;
}

.ModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: 500;
}
.sendRequestBtn {
  margin-top: 1.88rem;
  width: 100%;
}
.SendRequestModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;

  max-width: 100%;
  max-height: 98%;

  overflow-y: auto;

  padding: 20px;
  margin: auto;

  background-color: #19173b;
  border-radius: 8px;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.SendRequestModal::-webkit-scrollbar {
  width: 0px;
}
.SendRequestModal::-webkit-scrollbar-track {
  background: transparent;
}
.SendRequestModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.Title {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 5;
}

.ChooseType {
  position: relative;

  width: 409px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.ChooseType > * {
  width: calc(50% - 20px);
}

.ModalFields {
  position: relative;

  width: 409px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ModalFormInputs {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;

  flex-direction: column;
  gap: 10px;
}

.ModalFormInputs > .ModalFormInput {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;

  flex-direction: column;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-feature-settings: "calt" off;

  margin: 0% auto 20px auto;

  color: #e8e7ec;
}
/* .ModalFormInputs > .ModalFormInput > *:nth-child(2):focus-visible {
  outline: 2px solid rgb(130, 111, 238);
} */

.input-invalid {
  border-top: 1px solid #413e74 !important;
  border-left: 1px solid #413e74 !important;
  border-right: 1px solid #413e74 !important;
  border-bottom: 6px solid rgba(238, 74, 74, 0.651) !important;
  border-radius: 4px !important;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  ) !important;
  padding-left: 10px;
  height: 50px;
  margin-bottom: 10px;
  color: #e8e7ec;
  outline: none;
}
.input-valid {
  border-top: 1px solid #413e74 !important;
  border-left: 1px solid #413e74 !important;
  border-right: 1px solid #413e74 !important;
  border-bottom: 6px solid rgba(55, 204, 55, 0.629) !important;
  border-radius: 4px !important;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  ) !important;
  padding-left: 10px;
  height: 50px;
  margin-bottom: 10px;
  color: #e8e7ec;
  outline: none;
}

label {
  color: #e8e7ec;
  margin-bottom: 3px;
  margin-top: 0.56rem;
}

.login-btn {
  text-transform: capitalize;
  color: black;
  padding: 0.75rem 3rem;
  height: auto !important;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 14px !important;
  background-color: #ffcf66;
  font-weight: 600;
}
</style>
<style scoped>
@media (max-width: 480px) {
  .SendRequestModal {
    max-width: 90%;
    max-height: 98%;

    padding: 20px;
    margin: auto;
  }

  .ChooseFieldForRegList {
    gap: 8px;
    width: 100%;
  }
  .ChooseFieldForRegList > div {
    flex-direction: column;
    width: 100%;

    gap: 8px;

    margin: 0% !important;
  }

  .Card {
    padding: 20px;
    width: 100%;
    height: fit-content;

    gap: 8px;
  }
  .Card:hover {
    background-color: #27235c !important;
  }
  .Card > .Head {
    height: fit-content;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
  }
  .Card > .Desc {
    height: fit-content;

    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
  }
  .Card > .Button {
    display: none;
    padding: 8px;

    width: 100%;
    height: fit-content;

    font-size: 12px;
    line-height: 130%;
    opacity: 0;
  }

  .Card.ActiveMobile {
    background: transparent !important;
    background-color: rgb(30, 27, 74) !important;
  }
  .Card.ActiveMobile > .Button {
    display: block;
    opacity: 1;
  }

  .Title > span {
    color: #fff;

    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin-right: 0;
    padding-right: 15px;
  }
}
@media (min-width: 480px) and (max-width: 905px) {
  .SendRequestModal {
    max-width: 90%;
    max-height: 98%;

    padding: 20px;
    margin: auto;
  }

  .ChooseFieldForRegList {
    gap: 8px;
    width: 100%;
  }
  .ChooseFieldForRegList > div {
    flex-direction: column;
    width: 100%;

    gap: 8px;

    margin: 0% !important;
  }

  .Card {
    padding: 20px;
    width: 100%;
    height: fit-content;

    gap: 8px;
  }
  .Card:hover {
    background-color: #27235c !important;
  }
  .Card > .Head {
    height: fit-content;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
  }
  .Card > .Desc {
    height: fit-content;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
  .Card > .Button {
    display: none;
    padding: 8px;

    width: 100%;
    height: fit-content;

    font-size: 12px;
    line-height: 130%;
    opacity: 0;
  }

  .Card.ActiveMobile {
    background: transparent !important;
    background-color: rgb(30, 27, 74) !important;
  }
  .Card.ActiveMobile > .Button {
    display: block;
    opacity: 1;
  }

  .Title > span {
    color: #fff;

    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-right: 0;
    padding-right: 20px;
  }
}
</style>
