<template>
  <template v-if="NotUseRouter == true">
    <a class="ButtonFooter ButtonFooterGradientInside" :href="ButtonLink">
      <span class="centerButtonText">
        <p :class="NeedIcon == true ? 'ButtonHaveIcon' : 'OnlyText'">
          {{ ButtonText }}
        </p>
        <img v-if="NeedIcon" src="@/assets/img/Landing/ButtonIcon.svg" alt="" />
      </span>
    </a>
  </template>
  <template v-else>
    <router-link
      class="ButtonFooter ButtonFooterGradientInside"
      :to="ButtonLink"
    >
      <span class="centerButtonText">
        <p :class="NeedIcon == true ? 'ButtonHaveIcon' : 'OnlyText'">
          {{ ButtonText }}
        </p>
        <img v-if="NeedIcon" src="@/assets/img/Landing/ButtonIcon.svg" alt="" />
      </span>
    </router-link>
  </template>
</template>

<script>
import "@fontsource/nunito-sans/700.css";

export default {
  name: "ButtonInsideGradient",
  props: {
    ButtonText: String,
    ButtonLink: String,
    NeedIcon: Boolean,
    NotUseRouter: Boolean,
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.ButtonFooter {
  text-decoration: none;

  width: 290px;
  height: 58px;

  margin: auto;
  padding: 16px;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #ffffff;
}
.ButtonFooterGradientInside {
  position: relative;

  text-align: center;

  border: 0px solid;
  backdrop-filter: blur(1px);
  border-radius: 4px;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);

  transition: 0.4s;
}
.ButtonFooterGradientInside:hover {
  opacity: 0.8;
}

.centerButtonText {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.centerButtonText > p {
  position: relative;
}
.ButtonHaveIcon {
  margin: auto 0px auto auto;
}
.OnlyText {
  margin: auto;
}

.centerButtonText > img {
  position: relative;

  width: 12px;
  height: 12px;

  margin: auto auto auto 14px;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .ButtonFooter {
    width: auto !important;
    height: auto !important;

    margin: auto !important;
    padding: 3.1vw 4.7vw !important;

    font-size: 4vw !important;
    line-height: 5vw !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .ButtonFooter {
    width: auto !important;
    height: auto !important;

    margin: auto !important;
    padding: 2.6vw 4.2vw !important;

    font-size: 3vw !important;
    line-height: 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ButtonFooter {
    width: auto !important;
    height: auto !important;

    margin: auto !important;
    padding: 1.6vw 3.2vw !important;

    font-size: 2vw !important;
    line-height: 3vw !important;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .ButtonFooter {
    width: auto !important;
    height: auto !important;

    margin: auto !important;
    padding: 1.6vw 3.2vw !important;

    font-size: 2vw !important;
    line-height: 3vw !important;
  }
}
@media (min-width: 1400px) {
}
</style>
