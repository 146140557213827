<template>
  <div :class="`BenefitsBlock VerticalBenefit`">
    <div :class="`Title`">
      <div :class="`BenefitIcon`" :style="benefitIcon"></div>
    </div>
    <div class="Text">{{ text }}</div>
  </div>
</template>

<script>
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/400.css";

export default {
  name: "SimpleBenefits",
  props: {
    icon: String,
    text: String,
  },
  computed: {
    benefitIcon() {
      const _icon = require(`./../../assets/img/Landing/SimpleBenefits/${this.icon}.svg`);
      return `
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 100%;
                background-image: url("${_icon}");
            `;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.BenefitsBlock {
  position: relative;

  width: 270px;
  height: 270px;

  margin: 0% 31px 100px 31px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: flex-start;

  block-size: auto;
}

.Title {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
}

.BenefitIcon {
  position: relative;

  flex-shrink: 0;

  width: 78px;
  height: 73.55px;
}

.BenefitTitle {
  position: relative;
  height: auto;

  margin: auto 6px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  line-height: 33px;
  letter-spacing: -0.750316px;

  color: #ffffff;
}

.Text {
  position: relative;

  margin-top: 11px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  line-height: 28px;
  letter-spacing: -0.500211px;

  color: rgba(255, 255, 255, 0.7);

  block-size: auto;
}

.IMGBenefit {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.VerticalBenefit .Title {
  flex-direction: column;
}
.VerticalBenefit .BenefitIcon {
  position: relative;
  margin: 0% auto;
}
.VerticalBenefit,
.VerticalBenefit .Text {
  text-align: center;
}

.UseThreeBlockPerLine {
  width: 30% !important;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .BenefitsBlock {
    margin: 7vw 0%;
  }
  .BenefitIcon {
    width: 20vw;
    height: 20vw;
  }
  .BenefitTitle {
    font-size: 6vw;
    line-height: 8vw;
  }
  .Text {
    text-align: justify;

    position: relative;
    font-size: 4vw;

    line-height: 6vw;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .BenefitsBlock {
    margin: 7vw 0%;
    width: 65vw;
  }
  .BenefitIcon {
    width: 20vw;
    height: 20vw;
  }
  .BenefitTitle {
    font-size: 6vw;
    line-height: 8vw;
  }
  .Text {
    text-align: justify;

    position: relative;
    font-size: 5vw;

    line-height: 7vw;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .BenefitsBlock {
    position: relative;
    width: 20%;
    margin: 0% auto 100px auto;
  }

  .BenefitIcon {
    width: 6.5vw;
    height: 6.5vw;
  }
  .BenefitTitle {
    font-size: 1.8vw;
    line-height: 2.4vw;
  }
  .Text {
    text-align: left;

    position: relative;
    font-size: 2vw;

    line-height: 2.8vw;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .BenefitsBlock {
    position: relative;
    width: 20%;
    margin: 0% auto 100px auto;
  }

  .BenefitIcon {
    width: 6.5vw;
    height: 6.5vw;
  }
  .BenefitTitle {
    font-size: 1.8vw;
    line-height: 2.4vw;
  }
  .Text {
    text-align: left;

    position: relative;
    font-size: 2vw;

    line-height: 2.8vw;
  }
}
@media (min-width: 1400px) {
  .BenefitsBlock {
    position: relative;
    width: 23%;
    margin: 0% auto 100px auto;
  }

  .BenefitIcon {
    width: 90px;
    height: 90px;
  }
  .BenefitTitle {
    font-size: 24px;
    line-height: 33px;
  }
  .Text {
    text-align: left;

    position: relative;

    font-size: 20px;
    line-height: 28px;
  }
}
</style>
