<template>
  <div class="NavbarWrapper">
    <div class="Navbar">
      <router-link class="NavbarBlock" to="/">
        <div class="OctagonLogo"></div>
      </router-link>
      <div class="NavbarBlock">
        <div id="MobileNavbar" @click="openBurgerMenu = true">
          <i class="bx bx-menu burger" />
        </div>

        <router-link
          :class="
            `ButtonLink ${ActiveLink == '/about' ? 'ButtonLinkSelected' : ''}`
          "
          to="/about"
        >
          О нас
        </router-link>
        <router-link
          :class="`ButtonLink ${ActiveLink == '/' ? 'ButtonLinkSelected' : ''}`"
          to="/"
        >
          Студентам
        </router-link>
        <router-link
          :class="
            `ButtonLink ${
              ActiveLink == '/business' ? 'ButtonLinkSelected' : ''
            }`
          "
          to="/business"
        >
          Сотрудничество
        </router-link>

        <router-link
          :class="
            `ButtonLink ${ActiveLink == '/vkr' ? 'ButtonLinkSelected' : ''}`
          "
          to="/vkr"
        >
          Конкурс ВКР 1С
        </router-link>

        <template v-if="!user">
          <router-link
            class="NewLandingButton noselect"
            to="/auth/signup-login"
            style="margin-right: 8px;"
          >
            Войти
          </router-link>
          <div class="NewLandingButton noselect" @click="SendRequestBusiness">
            Регистрация
          </div>
          <!-- <router-link
            class="NewLandingButton"
            to="/auth/signup-login#pills-register"
          >
            Регистрация
          </router-link> -->
        </template>
        <template v-else>
          <router-link
            class="NewLandingButton"
            :to="
              user &&
              user.hasOwnProperty(`organization`) &&
              user.organization.length > 0
                ? `/organization/profile`
                : `/beta/profile`
            "
          >
            Мой профиль
          </router-link>
        </template>

        <!-- <template v-if="!user && ActiveLink != '/business'">
          <router-link
            class="ButtonLink ButtonGradient"
            to="/auth/signup-login#pills-register"
          >
            Регистрация / Вход
          </router-link>
        </template>
        <template v-if="!user && ActiveLink == '/business'">
          <span
            class="ButtonLink ButtonGradient noselect"
            style="cursor: pointer;"
            @click="SendRequestBusiness"
          >
            Оставить заявку
          </span>
        </template>
        <template v-if="user">
          <router-link
            class="ButtonLink ButtonGradient"
            :to="
              user &&
              user.hasOwnProperty(`organization`) &&
              user.organization.length > 0
                ? `/organization/profile`
                : `/beta/profile`
            "
          >
            {{
              user &&
              user.hasOwnProperty(`organization`) &&
              user.organization.length > 0
                ? user.organization[0].organization_name
                : `Мой профиль`
            }}
          </router-link>
        </template> -->
      </div>
    </div>

    <el-drawer
      v-model="openBurgerMenu"
      direction="ttb"
      modal-class="BurgerMenuButtons"
      :with-header="false"
      size="auto"
    >
      <div class="BurgerMenuButtons">
        <div class="OctagonLogo"></div>

        <router-link
          :class="
            `ButtonLink ${ActiveLink == '/about' ? 'ButtonLinkSelected' : ''}`
          "
          to="/about"
        >
          О нас
        </router-link>
        <router-link
          :class="`ButtonLink ${ActiveLink == '/' ? 'ButtonLinkSelected' : ''}`"
          to="/"
        >
          Студентам
        </router-link>
        <router-link
          :class="
            `ButtonLink ${
              ActiveLink == '/business' ? 'ButtonLinkSelected' : ''
            }`
          "
          to="/business"
        >
          Партнерам
        </router-link>
        <router-link
          :class="
            `ButtonLink ${ActiveLink == '/vkr' ? 'ButtonLinkSelected' : ''}`
          "
          to="/vkr"
        >
          Конкурс ВКР 1С
        </router-link>
        <!-- <router-link class="ButtonLink" to="/gos">
          Государственному сектору
        </router-link> -->

        <ButtonGradientInside
          v-if="!user"
          :ButtonText="`Вход`"
          :ButtonLink="'/auth/signup-login'"
          :NeedIcon="true"
        />

        <template v-if="!user">
          <ButtonGradientInside
            ButtonText="Зарегистрироваться"
            ButtonLink="#"
            @click.native="
              openBurgerMenu = false;
              showRegistrationModal = true;
            "
            :NeedIcon="true"
          />
        </template>
        <template v-if="user">
          <ButtonGradientInside
            :ButtonText="`Мой профиль`"
            :ButtonLink="
              user &&
              user.hasOwnProperty(`organization`) &&
              user.organization.length > 0
                ? `/organization/profile`
                : `/beta/profile`
            "
            :NeedIcon="true"
          />
        </template>

        <ButtonGradient
          ButtonText="Список проектов"
          ButtonLink="/project/projectsList"
          :NeedIcon="true"
        />
      </div>
    </el-drawer>

    <SendRequest
      @sendFalseToParent="sendFalseToParent"
      :key="`${sendFalseToParent}${setTypeForModal}`"
      :setType="setTypeForModal"
      :showModal="showRequestModal"
    />

    <RegistrationModal
      @sendCloseRegistrationModal="sendCloseRegistrationModal"
      :openOrgRegistration="openOrgRegistration"
      :showModal="showRegistrationModal"
    />
  </div>
</template>

<script>
import SendRequest from "@/components/Landing/SendRequest";
import RegistrationModal from "@/components/Landing/RegistrationModal";

import ButtonGradientInsideElement from "@/components/CustomElements/ButtonGradient";

import ButtonGradientInside from "@/components/Landing/ButtonInsideGradient";
import ButtonGradient from "@/components/Landing/ButtonGradient";
import { mapGetters } from "vuex";

export default {
  props: {
    SendRequestBusiness: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    HideIfAuthed: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  name: "LandingNavbar",
  components: {
    ButtonGradient,
    ButtonGradientInside,
    ButtonGradientInsideElement,
    SendRequest,
    RegistrationModal,
  },
  data() {
    return {
      openBurgerMenu: false,

      showRequestModal: false,
      showRegistrationModal: false,
      setTypeForModal: "",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    ActiveLink() {
      return this.$route.path;
    },

    HideThis() {
      if (this.HideIfAuthed) {
        if (this.user) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    sendFalseToParent() {
      this.showRequestModal = false;
    },
    sendCloseRegistrationModal() {
      this.showRegistrationModal = false;
    },
    openOrgRegistration(type) {
      this.setTypeForModal = type;
      this.showRegistrationModal = false;
      this.showRequestModal = true;
    },
  },
};
</script>

<style scoped>
.NewLandingButton {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;
  margin: auto 0%;

  width: fit-content;
  height: fit-content;

  color: #656bff;
  background-color: #656bff40;

  border-radius: 4px;

  transition: 0.4s;
}
.NewLandingButton:hover {
  opacity: 0.8;
}

.NavbarWrapper {
  position: relative;
  width: 100%;

  z-index: 500;
}

.Navbar {
  position: relative;

  width: 100%;
  max-width: 1400px;

  height: auto;

  margin: 0% auto;

  padding: 30px 117px 30px 117px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Navbar > .NavbarBlock {
  position: relative;

  width: auto;

  display: flex;
  flex-direction: row;
  align-content: center;
}

.Navbar > .NavbarBlock:nth-child(1) > * {
  margin: auto 8.75px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(2),
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(3),
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(4) {
  margin: auto 28px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(5) {
  margin: auto 53px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(6) {
  margin: auto 0% auto 0px;
}

#MobileNavbar {
  position: relative;

  width: 100%;
  height: 53px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6vw;
}

.OctagonLogo {
  position: relative;

  width: auto;
  height: 4vw;

  aspect-ratio: 1 / 0.27;

  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: auto 115%;
  background-image: url("./../../assets/img/Landing/NewOctagonLogo.svg");

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.Navbar > .NavbarBlock > .ButtonLinkSelected,
.BurgerMenuButtons .ButtonLinkSelected {
  color: rgba(83, 185, 234, 0.7);
}
.ButtonLink {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.7);

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.ButtonGradient {
  position: relative;

  width: 210px;
  height: 40px;

  padding: 9px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 22px;

  text-align: center;

  color: #ffffff;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}
.ButtonGradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 1px; /* control the border thickness */
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

#MobileNavbar {
  position: relative;

  width: auto;
  height: 100%;

  margin: auto;

  display: none;
  z-index: 1001;
}
#MobileNavbar > i {
  position: relative;
  margin: auto auto auto 10px;
}

.ContentFooter {
  position: relative;

  display: flex;
  justify-content: center;

  padding: 60px 0%;
  background: rgba(30, 30, 32, 0.5);
}
</style>

<style scoped>
@media (max-width: 480px) {
  .Navbar {
    position: fixed;

    width: 100%;

    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    background-color: rgb(20, 20, 20);

    z-index: 100;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:not(#MobileNavbar) {
    display: none;
  }
  .Navbar > .NavbarBlock:nth-child(2) > #MobileNavbar {
    display: flex;
    color: #e0e0e0;
  }

  .OctagonLogo {
    width: 80vw;
    height: 10vw;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .Navbar {
    position: fixed;

    width: 100%;

    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    background-color: rgb(20, 20, 20);

    z-index: 100;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:not(#MobileNavbar) {
    display: none;
  }
  .Navbar > .NavbarBlock:nth-child(2) > #MobileNavbar {
    display: flex;
    color: #e0e0e0;
  }

  .OctagonLogo {
    width: 80vw;
    height: 8vw;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .OctagonLogo {
    height: 3vw;
  }

  .Navbar {
    padding: 2vw 20px;
    justify-content: space-between;
  }

  .ButtonLink {
    font-size: 15px;
  }

  .Navbar > .NavbarBlock:nth-child(2) > *:nth-child(2),
  .Navbar > .NavbarBlock:nth-child(2) > *:nth-child(3),
  .Navbar > .NavbarBlock:nth-child(2) > *:nth-child(4) {
    margin: auto 16px auto 0px;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:nth-child(5) {
    margin: auto 32px auto 0px;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:nth-child(6) {
    margin: auto 0% auto 0px;
  }

  .NewLandingButton {
    padding: 8px 12px;
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .OctagonLogo {
    height: 4vw !important;
  }

  .Navbar {
    padding: 2vw 20px;
  }
  .Navbar > .NavbarBlock:nth-child(2) {
    height: auto;
    margin: auto 0%;
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .OctagonLogo {
    height: 4vw !important;
  }

  .Navbar {
    padding: 2vw 6vw;
  }
  .Navbar > .NavbarBlock:nth-child(2) {
    height: auto;
    margin: auto 0%;
  }
}

@media (min-width: 1400px) {
  .OctagonLogo {
    height: 52.09px;
  }
}
</style>

<style scoped>
.BurgerMenuButtons {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: auto;

  padding: 20px 20px 12vw 20px;

  background-color: rgb(20, 20, 20);
}

/* .BurgerMenuButtons > * {

  margin: 4vw auto !important;
} */

.BurgerMenuButtons > div.OctagonLogo {
  margin: 4vw auto 8vw auto !important;
}

.BurgerMenuButtons > a.ButtonLink {
  margin: 1vw auto !important;
  font-size: 4vw;
}

.BurgerMenuButtons a.ButtonLink:nth-child(5) {
  margin-bottom: 8vw !important;
}

.BurgerMenuButtons a.ButtonFooter {
  margin: 2vw auto !important;
  width: 60vw !important;
}
</style>
