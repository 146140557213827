<template>
  <div class="project-list-wrapper">
    <span class="PublicProjectsCounter"
      >Количество проектов на сайте:
      {{ OurProjectsCache.length + BusinessProjectsCache.length }}</span
    >

    <!-- <div class="project-filters">
      <el-select
        v-model="ListFilters"
        multiple
        filterable
        placeholder="Выберите направления для фильтрации"
        class="ChoosedFilters"
        @change="GenerateProjectListWithFilters"
      >
        <el-option
          v-for="item in ApprovedDirections"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div> -->

    <div
      class="project-list"
      v-loading="ProjectLoading"
      element-loading-background="#00000000"
      element-loading-text="загрузка"
    >
      <el-scrollbar height="100%">
        <div
          v-for="(project, i) in ResultProjectsList"
          :key="`${i}${project.id}${project.name}`"
          v-show="ProjectLoading == false"
        >
          <ProjectCardV2
            :project="project"
            :NeededSelectButton="NeedButton == 'none' ? false : true"
            :SelectProject="() => {}"
            :SelectedProject="null"
            :key="null"
            :checkClosedProjects="checkClosedProjects == 'none' ? false : true"
          ></ProjectCardV2>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import ProjectCardV2 from "@/components/Project/ProjectCard";

import { getStudentProject } from "@/api/project.js";
import { getApprovedProjectsWithTasks } from "@/api/n_project.js";

export default {
  components: { ProjectCardV2 },
  props: {
    SelectProject: { type: Function },
    NeedButton: String,
    checkClosedProjects: String,
  },
  data() {
    return {
      ProjectLoading: true,

      OurProjectsCache: [],
      BusinessProjectsCache: [],
      ResultProjectsList: [],
    };
  },
  async created() {
    this.ProjectLoading = true;

    let OurProjects = await getStudentProject();
    this.OurProjectsCache = OurProjects.data;

    let OrgProjects = await getApprovedProjectsWithTasks();
    this.BusinessProjectsCache = OrgProjects.data;

    this.ResultProjectsList = this.ResultProjectsList.concat(
      this.sortProjectsByDate(this.BusinessProjectsCache)
    );

    this.ResultProjectsList = this.ResultProjectsList.concat(
      this.sortProjectsByDate(this.OurProjectsCache)
    );

    this.OffLoading();
  },
  computed: {},
  methods: {
    sortProjectsByDate(projectList) {
      //Функция сортирует список проектов: Новые проекты (Дата добавления) - в начале списка
      let result = projectList.sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      return result;
    },

    OffLoading() {
      let self = this;
      setTimeout(function() {
        self.ProjectLoading = false;
      }, 150);
    },
  },
};
</script>

/* STYLE */
<style scoped>
.PublicProjectsCounter {
  position: relative;

  padding-top: 20px;
  padding-bottom: 20px;

  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;

  color: #e8e7ec !important;
}

.help_proj_btn {
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgb(204, 204, 204);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  font-size: 90%;
  color: rgb(57, 57, 57);
}

.project-list-wrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.project-filters {
  position: relative;

  display: flex;
  justify-content: center;

  width: 100%;
  margin: 3% 0%;
  padding: 0% 2%;
  opacity: 1;
}
.project-filters > *:not(:nth-last-child(1)) {
  margin-right: 1%;
}
.project-filters > * {
  margin-left: 0% !important;
}
.ChoosedFilters {
  position: relative;
  width: 100%;
}
.project-list {
  position: relative;
  min-height: 400px;

  overflow-y: hidden;

  padding: 0% 2%;
}

.project-list-empty {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped>
@media (max-width: 479px) {
  .PublicProjectsCounter {
    padding-top: 4px;
    padding-bottom: 12px;
    font-size: 12px;
  }
  .project-list-wrapper > .project-filters {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .project-list-wrapper > .project-filters > *:nth-child(1) {
    width: 100%;
    margin: 0% 0px 10px 0%;
  }

  .project-list-wrapper > .project-filters > *:nth-child(2) {
    width: 100%;
    margin: 0% 0% 10px 0%;
  }
  .project-list-wrapper > .project-filters > *:nth-child(3) {
    width: 100%;
    margin: 0% 0% 10px 0%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .PublicProjectsCounter {
    padding-top: 4px;
    padding-bottom: 12px;
    font-size: 13px;
  }

  .project-list-wrapper > .project-filters {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .project-list-wrapper > .project-filters > *:nth-child(1) {
    width: 100%;
    margin: 0% 0px 10px 0%;
  }

  .project-list-wrapper > .project-filters > *:nth-child(2) {
    width: 49.5%;
    margin: 0% auto 10px 0%;
  }
  .project-list-wrapper > .project-filters > *:nth-child(3) {
    width: 49.5%;
    margin: 0% 0% 10px auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .PublicProjectsCounter {
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 16px;
  }
  .project-list-wrapper > .project-filters {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .project-list-wrapper > .project-filters > *:nth-child(1) {
    width: 100%;
    margin: 0% 0px 10px 0%;
  }

  .project-list-wrapper > .project-filters > *:nth-child(2) {
    width: 49.5%;
    margin: 0% auto 10px 0%;
  }
  .project-list-wrapper > .project-filters > *:nth-child(3) {
    width: 49.5%;
    margin: 0% 0% 10px auto;
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .PublicProjectsCounter {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
  }
}
</style>
