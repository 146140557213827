<template>
  <a href="https://t.me/octagon_help_bot" target="_blank">
    <el-tooltip
      class="box-item"
      effect="customized"
      content="Обратиться в службу поддержки"
      placement="left"
    >
      <div class="ButtonTelegram" />
    </el-tooltip>
  </a>
</template>

<style scoped>
a {
  position: fixed;
  width: 65px;
  height: auto;

  aspect-ratio: 1 /1;

  z-index: 99999;
}

.ButtonTelegram {
  position: relative;
  width: 65px;
  height: auto;

  aspect-ratio: 1 /1;

  background: url("./../../assets/img/Landing/NewFeedbackIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ButtonTelegram:hover {
  animation: hithere 1s infinite;
}

@keyframes hithere {
  30% {
    transform: scale(1.1);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.1);
  }
  50% {
    transform: rotate(20deg) scale(1.1);
  }
  70% {
    transform: rotate(0deg) scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 479px) {
  .ButtonTelegram {
    position: fixed;
    width: 60px;
    z-index: 21;
    right: 10px;
    bottom: 70px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .ButtonTelegram {
    position: fixed;
    width: 65px;
    z-index: 21;
    right: 10px;
    bottom: 70px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ButtonTelegram {
    position: fixed;
    z-index: 21;
    right: 10px;
    bottom: 70px;
  }
}
@media (min-width: 992px) and (max-width: 1399px) {
  .ButtonTelegram {
    position: fixed;
    z-index: 21;
    right: 10px;
    bottom: 10px;
  }
}
@media (min-width: 1400px) {
  .ButtonTelegram {
    position: fixed;
    z-index: 21;
    right: 10px;
    bottom: 10px;
  }
}
</style>
